<template>
  <v-data-table
    :headers="headers"
    :items="allUser"
    :items-per-page="10"
    :search="search"
    class="elevation-1"
  >
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">fas fa-edit</v-icon>
      <v-icon small @click="deleteUser(item)"> far fa-trash-alt</v-icon>
    </template>
    <template v-slot:[`top`]>
      <v-toolbar flat>
        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.first_name"
                      label="user Name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.email"
                      label="Email"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.phone"
                      label="phone"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-text-field
        v-model="search"
        label="Search (UPPER CASE ONLY)"
        class="mx-4"
      ></v-text-field>
    </template>
    <template v-slot:[`item.status`]="{ item }">
      <v-btn
        v-if="item.status === '1'"
        class="ma-2"
        text
        icon
        color="blue lighten-2"
      >
        <v-icon>mdi-thumb-up</v-icon>
      </v-btn>
      <v-btn v-else class="ma-2" text icon color="red lighten-2">
        <v-icon>mdi-thumb-down</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "UserProfil",
  data: () => ({
    search: "",
    dialog: false,
    editedItem: {
      first_name: "",
      email: "",
      phone: 0
    }
  }),
  created() {
    this.loadUser();
  },
  computed: {
    ...mapGetters("users", ["allUser"]),
    headers() {
      return [
        { text: "User Name", value: "first_name" },
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Status Aktivasi", value: "status" },
        { text: "Actions", value: "actions", sortable: false }
      ];
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit User";
    }
  },
  methods: {
    ...mapActions("users", ["loadUser"]),
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value
          .toString()
          .toLocaleUpperCase()
          .indexOf(search) !== -1
      );
    },
    deleteUser(users) {
      this.$store.dispatch("users/removeUser", users);
    },
    editItem(item) {
      this.editedIndex = this.allUser.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.loadUser();
    },
    save() {
      this.$store.dispatch("users/updateUser", this.editedItem);
      this.close();
    }
  }
};
</script>
